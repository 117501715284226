body {
  margin: 0;
  font-family: Tajawal;
  direction: rtl;
  background-image: url("./assets/school6.png");
  overflow-x: hidden;
}

.payment-success-page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  background-image: url("./assets/school6.png");
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 770px) {
  .selects-wrapper {
    flex-wrap: wrap;
  }
  .order-table {
    zoom: 80% !important;
  }
}
@media (max-width: 600px) {
  .order-table {
    zoom: 75% !important;
  }
}
@media (max-width: 500px) {
  .order-table {
    zoom: 70% !important;
  }
}

p,
div,
h1,
h2,
h3,
h4,
h5 {
  font-family: Tajawal;
}

.disabled-size {
  text-decoration: line-through;
  opacity: 0.7;
  cursor: not-allowed !important;
}
.disabled-red {
  width: 65%;
  background-color: red;
  height: 2px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotate(-30deg);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.login-logo-wrapper {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.login-row {
  padding: 0% 10%;
}

label {
  margin-bottom: 6px;
  color: #000;
}

.login-word {
  font-size: 18px;
  font-weight: bold;
}

.text-color {
  color: #000;
}

.app-input {
  margin-bottom: 10px;
}

.login-button {
  background-color: #3ca556;
  color: #fff;
  border: none;
  padding: 5px 20px;
  border-radius: 4px;
}

.radius4 {
  border-radius: 4px;
}

.login-button:disabled {
  background-color: #e6ecee;
}

.login-button:disabled:hover {
  background-color: #a6dfb4;
}

.proceed-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 20px;
}

.login-button:hover,
.proceed-button:hover {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 20px;
}

.how-to-create p {
  color: #000;
  font-family: Tajawal;
}

.forget-password {
  color: #3ca556;
  cursor: pointer;
}

.forget-password:hover {
  color: #515a6e;
}

.login-notice {
  color: #c0392b;
  font-size: 20px;
  font-weight: bold;
  margin: 0% 2%;
}

.app-checkbox,
.checkout-checkbox {
  height: 15px;
  width: 15px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.register-form {
  width: 500px;
  margin-top: 30px;
}

.app-nav-bar {
  border-bottom: 2px solid #ddd;
  width: 100% !important;
  background-color: #fff;
}

.dropdown-item {
  text-align: right !important;
  cursor: pointer;
}

.invoice-logo {
  display: none;
}
.products-invoice{
  width: 300px;
  display: none;
  column-gap:20px ;
  grid-template-columns: auto auto auto auto auto auto;
}
.grid-item {
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  text-wrap: wrap;
}
@media print {
  .hide-print {
    display: none;
  }
  .products-invoice{
    display: grid;
  }
}
@media print {
  body {
    margin-bottom: 10px;
}
  .invoice  {
    max-width: 350px !important;
  }
  .invoice * {
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .invoice table {
    display: none;
  }
  .invoice-logo {
    display: block;
    margin-bottom: 30px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
  }
  .large-invoice-logo {
    display: none;
  }
  .invoice .includes {
    width: 300px;
    display: flex;

    justify-content: center;
    text-align: right !important;
  }
  .invoice .branch-invoice {
    padding: 5px !important;
  }
}

.nav-link:hover {
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
}

.nav-links {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

.cart-notification {
  border-radius: 100%;
  padding: 2px;
  color: #ce2536;
  margin-right: 2px;
}

.nav-icon {
  font-size: 19px;
  color: #1f61aa;
}

.navbar-logo {
  height: 60px;
  width: 60px;
}

.name-school {
  font-size: 14px;
}

.home-page-headers-wrapper {
  width: 500px;
  margin-top: 10px;
}

.home-page-headers {
  cursor: pointer;
  font-weight: bold;
}

.school-name {
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.sub-cat-card {
  height: 340px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
}

.product-card {
  height: 400px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
  position: relative;
}

.fav-outside {
  position: absolute;
  top: 15px;
  right: 15px;
}

.share-outside {
  position: absolute;
  top: 15px;
  left: 15px;
}

.product-card-data {
  position: absolute;
  bottom: 5px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-card {
  padding-bottom: 10px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
}

.sub-cat-card-image {
  max-height: 270px;
  max-width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product-image-wrapper {
  height: 302px;
}

.produce-title {
  margin-right: 3px;
  font-size: 18px;
}

.carousel-image {
  max-height: 400px;
}

.product-price {
  margin-right: 3px;
  font-size: 14px;
  margin-top: 0px;
}

.sub-cat-title {
  font-weight: bold;
  color: #515a6e;
}

.sub-cat-cards-wrapper {
  width: 90%;
  margin-right: 5% !important;
}

.follow-us-wrapper {
  background-color: #ddd;
  padding: 10px 0px;
}

.follow-us-text {
  color: #1f61aa;
  font-weight: bold;
  text-align: center;
}

.social-media-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.social-media-wrapper {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.footer-data {
  background-color: #1f61aa;
  margin-bottom: -10px;
  padding: 40px 0px;
}

.footer-wrapper {
  width: 90%;
}

.footer-links p {
  color: #fff;
  margin-top: 0px;
  margin-bottom: 5px;
}

.footer-links h2 {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}

.products-page {
  background-color: red;
  height: 200px;
}

.products-cards-wrapper {
  margin-right: 5% !important;
}

.select-filter {
  width: 150px !important;
  margin-right: 5px;
}

.selects-wrapper {
  display: flex;
}

.filter-select {
  margin: 3px 7px;
}

.bold {
  font-weight: bold;
}

.color-wrapper {
  display: flex;
  align-items: center;
}

.color-radio {
  margin-left: 10px;
}
.quantity-counter span {
  border: 1px solid rgb(165, 165, 165);
  padding: 8px 15px;
  width: 300px;
}

.quantity-counter input {
  border: 1px solid rgb(165, 165, 165);
  padding: 8px 15px;
  width: 60px;
  text-align: center;
}

.quantity-counter .minus {
  border: 1px solid #dc3545;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 15px;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
}

.quantity-counter .minus:hover {
  border: 1px solid #dc3545;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 8px 15px;
  background-color: #ce2536;
  color: #fff;
  cursor: pointer;
}

.quantity-counter .plus {
  border: 1px solid #3ca556;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px 15px;
  background-color: #3ca556;
  color: #fff;
  cursor: pointer;
}

.quantity-counter .plus:hover {
  border: 1px solid #3ca556;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px 15px;
  background-color: #228a3c;
  color: #fff;
  cursor: pointer;
}

.color-title {
  width: 70px;
}

.seasons-filter-wrapper input[type="radio"] {
  display: none;
}

.seasons-filter-wrapper label {
  border: 1px solid rgb(190, 189, 189);
  padding: 3px 6px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 3px;
  border-radius: 2px;
}

.seasons-filter-wrapper input[type="radio"]:checked + label {
  background-color: rgb(136, 179, 143);
}

.choose-color input[type="radio"] {
  display: none;
}

.choose-color label {
  border: 1px solid rgb(190, 189, 189);
  padding: 3px 6px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 3px;
  border-radius: 2px;
}

.choose-color input[type="radio"]:checked + label {
  background-color: rgb(136, 179, 143);
}

.add-to-cart-butt {
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.quantity-select {
  width: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
}

.add-to-cart-butt:hover {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #333;
}

.buy-butt {
  padding: 10px 30px;
  background-color: #dc3545;
}

.buy-button {
  background-color: #dc3545;
}

.buy-butt:hover {
  padding: 10px 30px;
}

.add-to-fav-butt {
  font-size: 22px;
  color: #3ca556;
  cursor: pointer;
}

.cart-table {
  width: 70% !important;
}

.cart-table-image {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  margin-left: 4px;
}

.cart-image-title {
  display: flex;
  align-items: center;
}

.cart-content td {
  height: 116px;
}

.flex-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.price-cart {
  margin-top: 35px;
}

.delete-icon {
  font-size: 22px;
  color: #dc3545;
  cursor: pointer;
}

.link-in-text {
  color: #3ca556;
  cursor: pointer;
}

.price-proceed-wrapper {
  width: 60%;
}

.progress-bar {
  height: 5px;
  background-color: #a3a3a3 !important;
  border-radius: 2px;
  width: 100%;
}

.progress-inner {
  height: 5px;
  width: 0%;
  background-color: #3ca556;
  border-radius: 5px;
}

.progress-inner2 {
  height: 5px;
  width: 20%;
  background-color: #3ca556;
  border-radius: 5px;
  transition: ease 0.8s;
}

.progress-inner3 {
  height: 5px;
  width: 40%;
  background-color: #3ca556;
  border-radius: 5px;
  transition: ease 0.8s;
}

.progress-step-numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  width: 100% !important;
}
.blur {
  color: rgb(165, 165, 165);
}

.clear {
  color: #000;
  transition: ease 0.8s;
}

.view-product-button {
  margin-top: -13px !important;
}

.fourth-step-wrapper {
  background-color: #eaf6ff;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 6px;
  padding: 20px 10%;
}

.fifth-step-wrapper {
  padding: 20px;
}

.attention-payment {
  color: #dc3545;
  font-size: 18px;
  margin-top: 20px;
}

.cost-top-content {
  margin-bottom: 20px;
}

.cost-bottom-content {
  margin: 40px 15%;
}

.cost-hr {
  margin: 0px 10%;
}

.inquiries-wrapper {
  margin: 0% 10%;
}

.inquiries-radios-wrapper {
  width: 150px;
  margin-bottom: 10px;
}

.fixed-login {
  width: 140px;
  height: 34px;
}

.login-loader {
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}

.login-error {
  margin-bottom: 0px;
  font-size: 14px;
  color: #ce2536;
  margin-top: 4px;
}

.register-error {
  margin-bottom: 0px;
  font-size: 14px;
  color: #ce2536;
  margin-top: -7px;
}
.succes-coupon {
  margin-bottom: 0px;
  font-size: 14px;
  color: #3ca556;
  margin-top: -7px;
}

.notification-text {
  color: #000;
  font-weight: bold;
}

.view-password {
  background-color: rgb(224, 224, 224);
  border: none;
  height: 38px;
  width: 40px;
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #3ca556;
}

.apply-coupon {
  background-color: #3ca556;
  border: none;
  height: 38px;
  width: 220px !important;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 4px;
  color: #fff;
}

.apply-coupon:disabled {
  background-color: #3ca556;
}

.apply-coupon:disabled:hover {
  background-color: #3ca556;
}

.apply-coupon:hover {
  background-color: #333;
}

.view-password-clicked {
  background-color: rgb(224, 224, 224);
  border: none;
  height: 38px;
  width: 40px;
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #1d66aa;
}

.pass-input {
  border-left: none !important;
}

.update-info-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.support-widget {
  background-color: rgba(164, 210, 255, 0.17) !important;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 3px;
  cursor: pointer;
  transition: ease 0.3s;
}

.support-widget:hover {
  background-color: rgba(0, 9, 17, 0.17) !important;
  transition: ease 0.3s;
}

.show-content {
  transition: ease 0.5s;
}

.hide-content {
  display: none;
  transition: ease 0.5s;
}
.pointer {
  cursor: pointer;
}

.modal-header-items {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -5px;
}

.modal-body {
  padding: 5px;
}

.times {
  font-size: 26px;
}

.size-table {
  max-width: 100%;
  margin-top: 50px;
}

.product-video {
  width: 100%;
  height: 50%;
  border-radius: 6px;
}

.size-simulator {
  max-width: 100%;
}

.full-times {
  margin-left: 25px;
  cursor: pointer;
}

.profile-buttons {
  width: 180px;
}

.red-butt {
  background-color: #ce2536;
}

.stage-dropdown {
  width: 300px;
  background-color: rgb(253, 254, 255);
  text-align: center;
  cursor: pointer;
}

.stage-dropdown:hover {
  width: 300px;
  background-color: rgb(172, 211, 255);
  text-align: center;
  cursor: pointer;
  transition: ease 0.3s;
}

.down-icon {
  color: #1f61aa;
  font-size: 16px !important;
}

.price-text {
  font-size: 14px;
}

.visa {
  max-width: 50px;
  margin-bottom: -20px;
}

.arab {
  max-width: 70px;
  margin-bottom: -20px;
}

.center-spinner {
  height: 100vh;
}

.blue {
  color: #1f61aa;
}

.seasons-filter-wrapper {
  display: flex;
}

.carousel-direction {
  direction: ltr;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.note-wrapper {
  background-color: rgb(255, 214, 214);
}

Link {
  text-decoration: none;
}

.checkout-radio {
  height: 17px;
  width: 17px;
  background-color: #4f7ec0;
  margin-left: 3px;
  border: 1px solid #ddd;
}
.flex-radio {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination span {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination span.active {
  background-color: #1f61aa;
  color: white;
  border-radius: 5px;
}

.pagination span:hover:not(.active) {
  background-color: #ddd;
}

.show-order-icon {
  font-size: 14px;
  background-color: #1f61aa;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 4px;
}

.order-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  padding: 30px !important;
  margin-bottom: 30px;
}

.order-data-header {
  color: #1f61aa;
}

.broken-heart {
  max-height: 300px;
  margin-bottom: 15px;
}

.exchange-button {
  background-color: #1f61aa;
  margin-bottom: 10px;
}

.exchange-return-wrapper {
  display: flex;
  flex-direction: column;
}

.hide-exchange {
  display: none;
  transition: ease 1.5s;
}

.exchange-inside {
  font-size: 12px;
}
.exchange-filters-section {
  display: flex;
  justify-content: space-between;
}
.exchange-radio-wrapper {
  height: 116px;
  display: flex;
  justify-content: right;
}
.exchange-radio-wrapper input {
  height: 20px;
  width: 20px;
}

.exchange-table {
  max-height: 500px !important;
}

.exchange-modal-wrapper {
  direction: ltr !important;
}

.table-header {
  margin-bottom: -20px !important;
}

.red-star {
  color: red;
}

.exclisuve-offer {
  background-color: #dc3545;
  position: absolute;
  top: 40px;
  color: #fff;
  border-radius: 15px 0px 0px 15px;
  font-size: 12px;
  padding: 5px;
}

.footer-logo-wrapper {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  padding: 20px;
  border-radius: 8px;
}

.footer-icons-wrapper {
  width: 130px;
  padding: 20px;
}

.old-price {
  color: #999;
  text-decoration: line-through;
  zoom: 70%;
}

.old-price-cart {
  color: #999;
  text-decoration: line-through;
  zoom: 90%;
  margin-right: 3px;
}

.discount-card {
  font-weight: 500;
  background-color: #dc3545;
  padding: 5px 10px;
  color: #fff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.invoice-discount {
  color: #666;
  font-size: 13px;
  text-decoration: line-through;
  margin-right: 2px;
}

.old-price-big {
  color: #777;
  zoom: 90%;
  text-decoration: line-through;
}

/* table {
  background-color: red;
  margin-right: -20px;
} */

.store-category-card {
  height: 250px;
  width: 250px;
  border: 1px solid rgb(174, 174, 174);
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;
  margin-bottom: 30px;
  border-radius: 4px;
  background-color: #fff;
}
.store-category-card:hover {
  scale: 1.05;
  transition: 0.3s ease;
}

.store-category-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center center;
  border-radius: 4px;
}

.store-category-card h5 {
  font-size: 16px;
  margin-top: 15px !important;
}

hr {
  width: 100%;
  height: 1px;
}

.logo-limits {
  max-width: 100px;
  max-height: 100px;
}

.branch-invoice {
  background-color: #fff;
  /* border: 4px solid #eee; */
  padding: 20px 50px;
  border-radius: 8px;
}

.branch-invoice h4 {
  font-size: 16px;
  font-weight: bold;
}

.branch-invoice h3 {
  font-size: 18px;
}

.branch-invoice img {
  width: 70px;
}
.branch-invoice th, .branch-invoice tr, .branch-invoice td {
  border: 1px solid #747474 !important;
}
@media (max-width: 1300px) {
  .add-to-cart-butt {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .exchange-button {
    background-color: #1f61aa;
    margin-bottom: 10px;
    font-size: 12px;
    width: 75px;
  }
  .cart-product-title {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: bold;
  }
  .cart-size {
    font-size: 12px;
    margin-top: 0px;
  }
  .cart-category-title {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .cart-table {
    width: 95% !important;
  }
  .checkout-checkbox {
    height: 30px !important;
    width: 30px !important;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .seasons-filter-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 3px;
  }
  .home-page-headers-wrapper {
    width: 300px;
  }
  .name-school {
    font-size: 12px !important;
  }
  .row {
    padding: 20px !important;
  }

  .price-proceed-wrapper {
    width: 90%;
  }
  .inquiries-wrapper {
    margin: 0% 0%;
  }
  .cost-top-content {
    margin: 30px 0%;
  }

  .cost-bottom-content {
    margin: 40px 0%;
  }
  .cost-bottom-content {
    font-size: 16px;
  }
  .progress-step-numbers h4 {
    font-size: 18px;
  }
  .progress-step-numbers span {
    font-size: 14px;
  }
  .login-button:hover {
    background-color: #3ca556;
  }
  .size-simulator {
    margin-top: 120px;
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
